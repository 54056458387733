@import "../../../../assets/theme/_var.scss";

.main_handle {
  display: flex;
  flex: 1 auto;
  position: relative;
  color: $white;
  // background-image: url(../../../../assets/images/images/bottom-left-vec.png),
    // url(../../../../assets/images/images/right-vec.png);
  // background-size: auto, auto;
  // background-position: left bottom, right top;
  // background-repeat: no-repeat;

  // @media (min-width: 1440px) {
  //   background-attachment: fixed;
  // }

  &_left {
    
    flex: 0 0 36rem;
    max-width: 36rem;
    background-color: rgba($white, 0.1);
    display: flex;

    @media (max-width: 1679px) {
      flex: 0 0 33rem;
      max-width: 33rem;
    }
  }

  &_right {
    position: relative;
    flex: 0 0 calc(100% - 36rem);
    max-width: calc(100% - 36rem);
    display: flex;
    flex-direction: column;

    &_content {
      padding: 3rem 4rem;
      min-height: calc(100vh - 16rem);
    }

    @media (max-width: 1679px) {
      flex: 0 0 calc(100% - 33rem);
      max-width: calc(100% - 33rem);

      &_content {
        padding: 3rem;
      }
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;

    &_left {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &_right {
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      flex: 1 auto;

      &_content {
        padding: 3rem 2rem;
      }
    }
  }

  @media (max-width: 767px) {
    &_right {
      &_content {
        padding: 1.5rem;
      }
    }
  }
}