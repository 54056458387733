@import '../../../assets/theme/_var.scss';

.commonPagination {
    display: inline-block;
    .pagination {
        li {
            .page-link {
                background-color: $basclr;
                border: 0;
                width: 28px;
                height: 28px;
                border-radius: 0.4rem;
                color: $white;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: normal;
                margin: 0 0.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                box-shadow: none;

                
            }
            &.active {
               .page-link {
                background-color: $orangeclr;
                color: $white;
                cursor: not-allowed;
               }
            }

            &.Prev_btn,
            &.Next_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;

                .page-link {
                    background-color: transparent;
                    background-image: url("../../../assets/images/pg-previcon.svg");
                    // width: 0.7rem;
                    // height: 1.1rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 1rem;
                    span {
                        display: none;
                    }

                    // span {
                    //     font-size: 3rem;
                    //     color: $basclr;
                    //     line-height: 0;
                    //     font-weight: normal;

                    //     // display: none;
                    //     &::before {
                    //         content: "<";
                    //     }
                    // }
                }
                &.disabled {
                    opacity: .5;
                }
            }

            &.Next_btn {
                .page-link {

                    background-image: url("../../../assets/images/pgNexticon.svg");
                    // span {
                    //     &::before {
                    //         content: ">";
                    //     }
                    // }
                }
            }
        }
    }
}