@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

// ***** fonts *****
$basefont: "Poppins", sans-serif;

// **** colors ****
$basclr:#1b4965;
$white: white;
$black: black;
$darkClr: #2a2b2f;
$yellowclr: #ff8200;
$orangeclr:#FFA01D;
$baseclr1: #143b53;
$textclr: #d5d4d8;
$borderclr: #ccc;
$redclr: #EB5757;
$greenclr:#1BA653;
$blueclr: #5479FE;
$placeholderclr: #9e9e9e;
$lightwhitebg: rgba(0, 0, 0, 0.05);
$scrollclr: rgba(0, 0, 0, 0.1);
$inputbg: rgba(0, 0, 0, 0.05);
$greytextclr: #6b6a6a;
$gradient: linear-gradient(180deg, #151518 0%, rgba(45, 43, 56, 0) 100%);
$greybg: rgba(47, 46, 56, 0.6);
$lightgray:#f5f5f5;

// **** z indexes *****
$loaderIndex: 1057;

// transtion
%transition {
    transition: 0.3s all ease-in-out;
}