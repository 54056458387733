@import '../../../assets/theme/_var.scss';

.full-page-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, .5); /* Slightly transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999; /* Higher than modal's default z-index (1040) */
  .text-primary {
    color: $white !important;
  }
}
