@import '../../../../assets/theme/_var.scss';

.notFound {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;


  // @media (max-width: 991px) {
  //   padding: 2rem 0;
  // }

  .error {
    font-size: 15rem;
    color: $basclr;
    text-shadow:
      1px 1px 1px $baseclr1,
      2px 2px 1px $baseclr1,
      3px 3px 1px $baseclr1,
      4px 4px 1px $baseclr1,
      5px 5px 1px $baseclr1,
      6px 6px 1px $baseclr1,
      7px 7px 1px $baseclr1,
      8px 8px 1px $baseclr1,
      25px 25px 8px rgba(0, 0, 0, 0.2);

    @media (max-width:1679px) {
      font-size: 9rem;
    }
  }

}