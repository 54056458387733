@import "../../../assets/theme/_var.scss";

.btn-style {
  height: 4.8rem;
  border-radius: .4rem;
  outline: none;
  border: 1px solid $basclr;
  background-color: $basclr;
  color: $white;
  font-size: 1.4rem;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
  padding: 1rem 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 991px) {
    padding: 1rem 2rem;
    height: 4.5rem;
  }

  &:hover {
    background-color: $orangeclr;
    border: 1px solid $orangeclr;
    color: $white;
  }

  &.samll-btn {
    font-size: 1.2rem;
    font-weight: 600;
    height: 3rem;
    min-width: 5rem;
    padding: 1rem 1.4rem;
  }

  &.border-btn {
    border: 1px solid $basclr;
    color: $basclr;
    background-color: $white;

    &:hover {
      background-color: $basclr;
      color: $white;
    }
  }

  &.white-border-btn {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;

    img {
      margin-right: 1rem;
    }

    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  &.accepted-btn {
    background-color: rgba(255, 130, 0, 0.17);
    border: 0;
    color: $basclr;

    &:hover {
      background-color: $basclr;
      color: $black;
    }
  }

  &.denied-btn {
    background-color: rgba(255, 31, 0, 0.17);
    border: 0;
    color: $redclr;

    &:hover {
      background-color: $redclr;
      color: $white;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    // op
  }
}