@import "../../../assets/theme/_var.scss";

.selected_token_table {
    table {
        thead {
            tr {
                th {
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:last-child {
                        text-align: right;
                        button {
                            svg {
                                path {
                                    stroke: $redclr;
                                }
                            }
                        }
                    }
                    
                    /* Styling for the status box */
                    .status-box {
                        padding: 5px 10px;
                        border-radius: 5px;
                        color: white;
                        font-weight: bold;
                        display: inline-block;
                        text-align: center;
                    }

                    /* Green box for False */
                    .green-box {
                        background-color: green;
                    }

                    /* Red box for True */
                    .red-box {
                        background-color: red;
                    }
                }
            }
        }
    }
}
