@import "./assets/theme/_var.scss";
* {
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}
html,
body,
#root,
.App,
main {
  display: flex;
  flex-direction: column;
}
#root,
body,
.App,
main {
  flex: 1 auto;
}
html {
  font-size: 62.5%;
  min-height: 100%;
  body {
    font-size: 1.6rem;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    font-family: $basefont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgaba($white, .7);
    background-color: $white;
    overflow-x: hidden;
    word-break: break-word;
  }
}
.App {
  justify-content: space-between;
  main {
    margin-bottom: auto;
  }
}
button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
// reset css
.row {
  margin: 0 -15px;
  >div {
    padding: 0 15px;
  }
}
.container {
  padding: 0 15px;
  max-width: 158rem;
  &-fluid {
    padding: 0 15px;
  }
  @media (max-width:1679px) {
    max-width: 130rem;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
a {
  text-decoration: none;
  @extend %transition;
  color: $basclr;
  &:hover {
    color: $orangeclr;
  }
}
img,
svg {
  max-width: 100%;
}
ul {
  padding-left: 0;
  list-style: none;
}
ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
  color: $basclr;
}
h2,
.h2 {
  font-size: 4.8rem;
  @media (max-width: 1439px) {
    font-size: 4.2rem;
  }
  @media (max-width: 991px) {
    font-size: 3.6rem;
  }
  @media (max-width: 575px) {
    font-size: 2.8rem;
  }
}
h3,
.h3 {
  font-size: 3rem;
  @media (max-width: 1679px) {
    font-size: 2.8rem;
  }
  @media (max-width: 1279px) {
    font-size: 2.4rem;
  }
  @media (max-width: 767px) {
    font-size: 2rem;
  }
}
h4,
.h4 {
  font-size: 2.2rem;
  @media (max-width: 1439px) {
    font-size: 2rem;
  }
  @media (max-width: 991px) {
    font-size: 1.8rem;
  }
  @media (max-width: 575px) {
    font-size: 1.6rem;
  }
}
h5,
.h5 {
  font-size: 2rem;
  @media (max-width: 1439px) {
    font-size: 1.8rem;
  }
  @media (max-width: 991px) {
    font-size: 1.6rem;
  }
  @media (max-width: 575px) {
    font-size: 1.4rem;
  }
}
h6,
.h6 {
  font-size: 1.8rem;
  @media (max-width: 1439px) {
    font-size: 1.6rem;
  }
  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
}
p,
.p {
  font-size: 1.6rem;
  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
  @media (max-width: 575px) {
    font-size: 1.2rem;
  }
}
input {
  &:-webkit-autofill {
    -webkit-text-fill-color: $white;
    overflow: clip;
    transition: background-color 5000s ease-in-out 0s;
    backdrop-filter: blur(5.4rem);
    -webkit-backdrop-filter: blur(5.4rem);
    --tw-shadow: 0 0 0px 1000px $lightgray inset !important;
    -webkit-box-shadow: 0 0 0px 1000px $lightgray inset !important;
  }
  &::placeholder {
    color: $white;
    opacity: .3;
  }
  &:-moz-placeholder {
    color: $white;
    opacity: .3;
  }
  &::-webkit-input-placeholder {
    color: $white;
    opacity: .3;
  }
}
@media (max-width:991px) {
  input {
    font-size: 1.2rem;
  }
}
.table {
  margin-bottom: 0;
  tr {
    border-bottom: 1px solid $borderclr;
    th {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: normal;
      color: $white;
      padding: 1.5rem 1.5rem;
      border: none;
      white-space: nowrap;
      background-color: $basclr;
      &:first-child {
        @media (max-width:1199px) {
          min-width: 10rem;
        }
      }
    }
    td {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;
      color: $black;
      vertical-align: middle;
      padding: 2rem 1.5rem;
      border: none;
      white-space: nowrap;
      // background-color: $lightwhitebg;
      .tableuserimg {
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 0.5rem;
        object-fit: cover;
      }
      .linkbtn {
        font-size: 1.2rem;
        font-weight: 600;
        color: $white;
        transition: all ease-in-out 0.3s;
        &:hover {
          color: $yellowclr;
        }
      }
      .btn-style {
        height: 3.6rem;
        padding: 0.8rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.multiSelectContainer {
  .searchWrapper {
    height: 4.8rem;
    @media (max-width:991px) {
      height: 4.5rem;
    }
    input {
      color: $black;
      &::placeholder {
        color: rgba($black, .8);
        font-size: 1.4rem;
      }
      &:-moz-placeholder {
        color: rgba($black, .8);
        font-size: 1.4rem;
      }
      &::-webkit-input-placeholder {
        color: rgba($black, .8);
        font-size: 1.4rem;
      }
    }
    .chip {
      background-color: $basclr;
    }
    &.singleSelect {
      >input {
        pointer-events: none;
      }
    }
  }
  .optionListContainer {
    .option {
      color: $basclr;
      font-size: 1.4rem;
      &:hover {
        color: $basclr;
        background-color: $lightgray;
      }
      &.selected,
      &.highlightOption {
        color: $white;
        background-color: $basclr;
      }
    }
  }
}
.form {
  &-check {
    text-align: left;
    label {
      color: $black;
      font-size: 1.4rem;
    }
    label,
    input {
      cursor: pointer;
    }
    input {
      position: relative;
      bottom: -.2rem;
    }
  }
 
  &-label {
    text-align: left;
    display: block;
}

&-control {
    height: 5rem;
    border: $basclr;
    color: $black;
    font-size: 1.4rem;
    background-color: $lightgray;
    padding: 1rem rem;

    &:-webkit-autofill {
        -webkit-text-fill-color: $black;
        overflow: clip;
        transition: background-color 5000s ease-in-out 0s;
        backdrop-filter: blur(5.4rem);
        -webkit-backdrop-filter: blur(5.4rem);
        --tw-shadow: 0 0 0px 1000px $lightgray inset !important;
        -webkit-box-shadow: 0 0 0px 1000px $lightgray inset !important;
    }

    &::placeholder {
        color: $black;
        opacity: .3;
    }

    &:-moz-placeholder {
        color: $black;
        opacity: .3;
    }

    &::-webkit-input-placeholder {
        color: $black;
        opacity: .3;
    }
    @media (max-width:991px) {
      height: 4.5rem;
    }
}
}
w3m-modal {
  z-index: 999;
}