@import "../../../../assets/theme/_var.scss";
.dashboard {
  &_page {
    &_action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > div {
        &:not(.csv) {
          margin-right: 1rem;
          width: 100%;
          max-width: 18rem;
          .selectbox {
            width: 100%;
          }
        }
      }
      @media (max-width: 767px) {
        padding-top: 1rem !important;
        justify-content: flex-start;
      }
      @media (max-width: 479px) {
        flex-direction: column;
        > div {
          &:not(.csv) {
            margin-right: 0;
            max-width: 100%;
          }
        }
        .csv {
          width: 100%;
          label {
            margin-top: 1rem;
            width: 100%;
          }
        }
      }
    }
    .no-data-available {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; // Full height to center vertically
      color: black;
      font-size: 1.2rem;
    }
  }
}
