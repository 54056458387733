@import "../../../assets/theme/_var.scss";

.footer_sec {
    margin-top: auto;
    padding: 2.8rem 4rem;
    backdrop-filter: blur(3rem);
    -ms-backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: $lightgray;

    @media(max-width: 1679px) {
        padding: 1.8rem 4rem;
    }

    @media(max-width: 767px) {
        padding: 1rem 4rem;
    }

    p {
        font-weight: 600;
        text-align: center;
        margin: 0;
        font-size: 1.4rem;
        color: $basclr;
    }

}