@import "../../../assets/theme/_var.scss";

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 36rem;
  padding: 3rem 4rem 3rem;
  z-index: 999;
  overflow-y: auto;
  background-color: $lightgray;

  @media (max-width:1679px) {
    max-width: 33rem;
  }

  &_logo {
    padding-bottom: 4rem;
    position: relative;

    @media (max-width:1679px) {
      img {
        max-width: 20rem;
      }
    }
  }

  &_menu {
    flex: 1 auto;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &_item {
      display: block;
      margin-bottom: 1rem;

      &_link {
        color: $basclr;
        font-size: 1.6rem;
        border-radius: 1.4rem;
        display: block;
        padding: 1.7rem 2rem;
        // @extend %transition;
        font-weight: 500;

        >svg {
          margin-right: 1rem;
        }
        >svg,
        > svg #swap_icon {
          path {
            fill: $baseclr1 ;
            stroke: $baseclr1 ;
            @extend %transition;
          }
        }

        &.active, &:hover {
          background: $basclr;
          color: $white;
          text-shadow: 0 0 1px $white;


          svg,
          svg #swap_icon {
            path {
              fill: $white ;
              stroke: $white ;
            }
          }
        }

        @media (max-width:1679px) {
          font-size: 1.4rem;
          padding: 1rem 2rem;
        }
      }

      &_dropdown {
        >a {
          color: $white;
          font-size: 1.6rem;
          border-radius: 3rem;
          display: flex;
          align-items: center;
          padding: 1.7rem 2rem;
          padding-right: 3rem;
          @extend %transition;
          font-weight: 500;
          position: relative;

          &:focus-visible {
            box-shadow: none;
          }

          >svg {
            margin-right: 1.4rem;
          }

          .icon {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 3rem;
            @extend %transition;
          }

          &:after {
            content: none;
          }
        }

        .dropdown-menu {
          >a {
            &.active {
              // display: none;
              background: transparent;
              color: $black;
            }
          }
        }

        &.show,
        &.active {
          >a {
            background: red;

            .icon {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          background-color: transparent;
          padding: 0;
          box-shadow: none;
          border: 0;
          width: 100%;
          transform: none !important;

          @media (min-width: 768px) {
            position: static !important;
            display: block !important;
            max-height: 0;
            transition: 0.15s all ease-out;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;

            &.show {
              opacity: 1;
              visibility: visible;
              max-height: 500px;
              transition: max-height 0.25s ease-in;
            }
          }

          @media (max-width: 767px) {
            bottom: 4.5rem !important;
          }

          >a {
            display: block;
            padding: 1rem 2rem 1rem 6rem;

            &:first-child {
              padding-top: 2rem;
            }

            &:last-child {
              padding-bottom: 2rem;
            }
          }
        }
      }

      .action-dropdown {
        >a {
          display: flex;
          align-items: center;

          svg {
            margin-right: 1rem;
          }

          &:after {
            content: none;
          }
        }

        .dropdown-menu {
          margin-bottom: 1rem;
        }
      }

      // &:last-child {
      //     margin-top: auto;

      //     a {
      //         background-color: transparent;
      //         background: none;
      //         border-radius: 0;
      //     }
      // }

      // &_title,
      // .icon {
      //   @media (max-width: 1279px) {
      //     display: none;
      //   }
      // }
    }
  }

  @media (max-width: 1279px) {
    padding: 3rem 2rem 3rem;
    background-color: rgba($white, .01);
    height: 100vh;
    width: 100%;
    max-width: 30rem;
    transform: translateX(-30rem);
    transition: .3s transform ease-in-out;
    z-index: 9999;
    backdrop-filter: blur(1rem);

    &.active {
      transform: translateX(0);
      box-shadow: 1rem 0 2rem rgba(0, 0, 0, .3);
    }



    &_logo {
      img {
        max-width: 16rem;
      }
    }

    &_menu {
      &_item {
        &_link {
          padding: 1rem;

          svg {
            margin: 0;
          }
        }

        &_dropdown {
          >a {
            padding: 1rem;
            text-align: center;
            justify-content: center;

            svg {
              margin: 0;
            }
          }

          .dropdown-menu {
            background: linear-gradient(0deg,
                rgba(28, 28, 28, 0.28),
                rgba(28, 28, 28, 0.28)),
              linear-gradient(135deg,
                rgba(245, 247, 250, 0.12) 0%,
                rgba(245, 247, 250, 0.06) 52.14%,
                rgba(245, 247, 250, 0.0001) 100%);
            border: 1px solid rgba(245, 247, 250, 0.06);
            backdrop-filter: blur(5.43rem);
            -webkit-backdrop-filter: blur(5.43rem);
            border-radius: 20px;
            padding: 1rem 0;
            width: 10rem;

            >a {
              padding: 1rem;
            }
          }
        }

        .action-dropdown {
          >a {
            justify-content: center;

            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width:991px) {
    max-width: 26rem;
  }

}