@import "../../../assets/theme/_var.scss";
.header {
  position: sticky;
  position: -webkit-sticky;
  padding: 0 2rem;
  top: 0;
  left: auto;
  right: 0;
  background: $lightgray;
  min-height: 8rem;
  width: 100%;
  max-width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  z-index: 999;
  font-weight: 600;
  .header_welcome {
    h6 {
      color: $basclr;
      @media (min-width: 480px) {
        br {
          display: none;
        }
      }
      @media (max-width: 1679px) {
        // font-size: 1.6rem;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
      @media (max-width: 1279px) {
        font-size: 1.4rem;
        // padding-top: .1rem;
        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
      @media (max-width: 767px) {
        font-size: 1.1rem;
      }
    }
    &_logo {
      flex-shrink: 0;
    }
    h6 {
      @media (max-width:1279px) {
        display: none;
      }
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    >.dropdown {
      margin-left: 2rem;
      display: inline-block;
    }
    .dropdown_main {
      .dropdown-toggle {
        @media (max-width: 1279px) {
          padding-right: 0 !important;
        }
        .common_dropdown_title {
          @media (max-width: 1279px) {
            display: none;
          }
        }
        &:after {
          @media (max-width: 1279px) {
            display: none;
          }
        }
      }
      .dropdown-menu {
        max-width: 24.5rem;
        min-width: 24.5rem;
      }
    }
    .bell_icon {
      height: 4rem;
      width: 4rem;
      min-height: 4rem;
      min-width: 4rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      text-align: center;
    }
    &_userdrop,
    &_walletdrop {
      >button {
        span:not(.arrow) {
          margin: 0 1rem;
        }
      }
      .dropdown-menu {
        margin-top: 1rem;
      }
    }
    &_userdrop {
      // .dropdown-menu {
      //     background-color: rgba($themeClr, 0.9);
      // }
      >button {
        >img,
        >svg {
          height: 3rem;
          width: 3rem;
          min-height: 3rem;
          min-width: 3rem;
          border-radius: 50%;
          object-fit: cover;
        }
        .arrow {
          svg {
            width: 1rem;
            // height: 1rem;
            opacity: 0.5;
          }
        }
      }
      >div {
        @media (min-width: 768px) {
          width: 33rem !important;
        }
        >a {
          padding-left: 1.8rem !important;
          padding-right: 1.8rem !important;
          text-align: left !important;
          svg {
            margin-right: 1rem;
            @media (max-width: 1679px) {
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
        >.dropdown {
          >button {
            border-top: 1px solid rgba($white, 0.2);
            padding: 1.45rem 1.8rem;
            padding-right: 4rem;
            text-align: left !important;
            display: block;
            width: 100%;
            position: relative;
            svg {
              margin-right: 1rem;
              @media (max-width: 1679px) {
                width: 1.8rem;
                height: 1.8rem;
              }
            }
            .icon {
              position: absolute;
              transform: translateY(-50%);
              top: 50%;
              right: 1.45rem;
              @extend %transition;
              margin: 0;
              svg {
                margin: 0;
              }
            }
            &:focus,
            &:active {
              box-shadow: none;
              outline: none;
              border-top: 1px solid rgba($white, 0.2);
            }
            @media (max-width: 1679px) {
              font-size: 1.4rem;
              padding-top: 1rem;
              padding-bottom: 1rem;
              .icon {
                svg {
                  width: 1.2rem;
                }
              }
            }
          }
          >div {
            position: static !important;
            display: block !important;
            max-height: 0;
            transition: 0.15s all ease-out;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            border: 0;
            border-radius: 0;
            width: 100%;
            background: transparent !important;
            margin: 0 !important;
            transform: none !important;
            padding: 0;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
            >a {
              display: block;
              padding: 1.45rem 2rem 1.45rem 6rem;
              border: 0 !important;
              text-align: left;
              backdrop-filter: blur(5.43rem);
              -webkit-backdrop-filter: blur(5.43rem);
              &:first-child {
                border-top: 1px solid rgba($white, 0.2) !important;
              }
              @media (max-width: 1679px) {
                font-size: 1.4rem;
                padding: 1rem 2rem 1rem 5.5rem;
              }
            }
            &.show {
              opacity: 1;
              visibility: visible;
              max-height: 500px;
              transition: max-height 0.25s ease-in;
              >button {
                .icon {
                  transform: translateY(-50%) rotate(180deg);
                }
              }
            }
          }
          &.show {
            >button {
              .icon {
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }
        }
      }
    }
    &_notifications {
      .dropdown-menu {
        padding-bottom: 2rem !important;
        background: rgba(27.63, 27.63, 27.63, 0.8);
        backdrop-filter: blur(3rem);
        -webkit-backdrop-filter: blur(3rem);
        width: 51.2rem !important;
        top: calc(100% + 1rem) !important;
        transform: none !important;
      }
      &_head {
        padding: 1.5rem 4rem;
        border-bottom: 1px solid rgba($white, 0.1);
        h6 {
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
      &_filter {
        padding: 3rem 4rem;
        ul {
          display: flex;
          align-items: center;
          li {
            a {
              margin-right: 1rem;
              font-size: 1.4rem;
              font-weight: 500;
              border-radius: 3rem;
              padding: 0.6rem 1.6rem;
              background: orange;
              color: $white;
              min-width: 6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              // &.active {
              //     @extend %bgGradient;
              // }
            }
            &:last-child {
              a {
                margin-right: 0;
              }
            }
          }
        }
      }
      &_box {
        &_outer {
          max-height: 38rem;
          padding: 0 4rem;
          padding-bottom: 3rem;
          .scrollbar-container {
            >div {
              &:not(:first-child) {
                margin-top: 1.6rem;
              }
            }
          }
        }
        margin-bottom: 1.6rem;
        // background-color: rgba($whiteEEE, 0.2);
        background-color: $white;
        border-radius: 2.2rem;
        padding: 1.6rem;
        &_inner {
          display: flex;
          align-items: center;
        }
        &_thumb {
          flex: 0 0 5.4rem;
          max-width: 5.4rem;
          img {
            height: 4rem;
            width: 4rem;
            min-height: 4rem;
            min-width: 4rem;
            border-radius: 50%;
            border: 1px solid $white;
          }
        }
        &_content {
          flex: 1 auto;
          padding-right: 2.6rem;
          h5,
          p {
            font-size: 1.4rem;
            font-weight: bold;
            color: $white;
            margin: 0;
            line-height: 1.2;
          }
          p {
            font-weight: 400;
            span {
              color: rgba($white, 0.6);
            }
          }
        }
        &_action {
          flex: 1 auto;
          text-align: right;
          .commonBtn.btn-small {
            font-size: 1.4rem;
            white-space: nowrap;
          }
          &_post {
            height: 7rem;
            width: 7rem;
            min-height: 7rem;
            min-width: 7rem;
            border-radius: 1.4rem;
          }
        }
      }
    }
    &_cart {
      display: inline-block;
      margin-left: 1rem;
    }
    &_notifications,
    &_walletdrop,
    &_cart {
      >button {
        height: 4.8rem;
        width: 4.8rem;
        min-height: 4.8rem;
        min-width: 4.8rem;
        border-radius: 50%;
        background-color: rgba($white, 0.1);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        text-align: center;
        &:hover,
        &:focus {
          background-color: rgba($white, 0.1) !important;
        }
        @media (max-width: 1679px) {
          height: 3rem;
          width: 3rem;
          min-height: 3rem;
          min-width: 3rem;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
    &_toggle {
      @media (min-width: 1280px) {
        display: none;
      }
      margin-left: 1rem;
      span {
        display: block;
        width: 24px;
        height: 2px;
        margin: 0.6rem 0;
        // border-top-left-radius: 2px;
        // border-bottom-left-radius: 2px;
        background-color: $basclr;
        transition: 500ms;
        transform-origin: right center;
        transition: 0.3s all ease-in-out;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.active {
        // display: none;
        span {
          width: 24px;
          &:first-child {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            transform: scale(0);
          }
          &:last-child {
            transform: rotate(45deg);
          }
        }
      }
    }
    .btn-style {
      @media (max-width:1279px) {
        padding: 1rem;
        line-height: 1.4;
      }
    }
  }
  &_logo {
    img {
      max-width: 12rem;
      width: 100%;
    }
    @media (min-width: 1280px) {
      display: none;
    }
  }
  .common_breadcrumb {
    @media (max-width: 767px) {
      display: none;
    }
  }
  @media (max-width: 1679px) {
    padding: 0 1.5rem;
    &_actions {
      &_notifications {
        .dropdown-menu {
          width: 40rem !important;
          // right: -4rem !important;
        }
        &_head {
          padding: 1.5rem 2rem;
        }
        &_filter {
          padding: 2rem;
          ul {
            a {
              min-width: inherit;
            }
          }
        }
        &_box {
          &_outer {
            height: 30rem;
            padding: 0 2rem;
            margin-bottom: 1rem;
            .scrollbar-container {
              >div {
                &:not(:first-child) {
                  margin-top: 1rem;
                }
              }
            }
          }
          margin-bottom: 1rem;
          padding: 1rem;
          &_thumb {
            flex: 0 0 4.8rem;
            max-width: 4.8rem;
          }
          &_content {
            flex: 1 auto;
            padding-right: 1.5rem;
          }
          &_action {
            .commonBtn.btn-small {
              font-size: 1.2rem;
              white-space: nowrap;
            }
            &_post {
              height: 5rem;
              width: 5rem;
              min-height: 5rem;
              min-width: 5rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1279px) {
    min-height: 7rem;
    padding: 0 0.5rem;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0;
    &_search {
      display: none;
    }
    &_actions {
      >.dropdown {
        margin-left: 1rem;
      }
      &_userdrop {
        >button {
          span {
            display: none;
          }
        }
        .dropdown-menu {
          margin-top: 1rem;
        }
      }
      &_notifications {
        .dropdown-menu {
          width: 29rem !important;
          right: -8rem !important;
        }
        &_head {
          padding: 1.5rem;
        }
        &_filter {
          padding: 2rem 1.5rem;
        }
        &_box {
          &_outer {
            height: 30rem;
            padding: 0 1.5rem;
          }
          &_inner {
            flex-wrap: wrap;
          }
          &_content {
            padding-right: 0;
            flex: 0 0 calc(100% - 4.8rem);
          }
          &_action {
            flex: 0 0 100%;
            padding-left: 4.8rem;
            padding-top: 0.5rem;
            text-align: left;
          }
        }
      }
    }
  }
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    z-index: -1;
  }
}
.sidebar-backdrop {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  left: 0;
  top: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}